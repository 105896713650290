(function ($) {
    let $window = $(window)

    function headingBlockAnimate() {
        $('.text-block').each(function (i, el) {
            if ($(el).offset().top < $(document).scrollTop() + $(window).height()) {
                $(el).addClass('active')
            }
        })
    }

    $window.load(function () {
        $('#main-container').addClass('animate')
        if ($('.about-us-section .rectangle-wrapper').offset().top + 20 < $(document).scrollTop() + $window.height()) {
            $('.about-us-section .rectangle-wrapper').addClass('animate')
        }
    })
    $(document).ready(function () {
        let flag = true
        var $el, leftPos, newWidth;

        $('.disclaimer-popup').click(function (e) {
            e.preventDefault()
            $('.popup-disclaimer').fadeIn(300)
        })
        $('.menu-mobile').click(function () {
            $('.wrapper-nav').fadeIn(200)

        })

        $(document).click(function (e) {
            //e.preventDefault()
            var div = $(".wrapper-popup-text");
            var div3 = $(".close-popup");
            var div2 = $(".disclaimer-popup a");
            var div4 = $(".wrapper-nav");
            var div5 = $(".close-nav");
            var div6 = $(".menu-mobile");
            if (!div.is(e.target)
                && div.has(e.target).length === 0 && !div2.is(e.target) || div3.is(e.target)) {
                $('.popup-disclaimer').fadeOut(300)
            }
            if ($window.width() < 1121) {
                if (!div4.is(e.target)
                    && !div6.is(e.target) || div5.is(e.target)) {
                    $('.wrapper-nav').fadeOut(200)
                }
            }
        })
        if (!$('.email').text().length) {
            $('.tel').addClass('no-line')
        }
        if ($window.width() > 992) {

            $(".nav-menu ul").each(function (i, el) {
                $(el).append("<li id='magic-line'></li>");
                $(el).find("li:not(#magic-line)").mouseover(function () {
                        $el = $(this);
                        leftPos = $el.position().left;
                        newWidth = $el.width();

                        $(el).find('#magic-line').stop().animate({
                            left: leftPos,
                            width: newWidth
                        });
                    },
                );
            })
        }
        if ($window.width() < 768) {
            $('.our-funds-square').append($('.our-funds-square .wrapper-square'))
            $('.our-core-values-section').prepend($('.our-core-values-section .rectangle-wrapper'))
            headingBlockAnimate()
        }
        var wpcf7Elm = document.querySelector('.contact-section  .wpcf7');
        wpcf7Elm.addEventListener('wpcf7invalid', function (event) {
            $('.wrapper-row').removeClass('error')
            setTimeout(function () {
                $('.wpcf7-not-valid').each(function (i, el) {

                    $(el).closest('.wrapper-row').addClass('error')
                })
            }, 200)


        }, false);
        wpcf7Elm.addEventListener('wpcf7mailsent', function (event) {
            $('.wrapper-row').removeClass('error')
            $('.thankyou-section').addClass('active')
        }, false);
        $('input').change(function () {
            $(this).css('color', '#000000')
        })

        let windowHeight = $window.height()
        let squareAnimate = $('.section-hero .square')
        let blockH = squareAnimate.height();
        let blockTop = squareAnimate.offset().top;
        let squareFunds = $('.our-funds-square .square')
        let squareStaff = $('.our-staff-section .square')
        let squareContact = $('.contact-section .wrapper-square')
        let offsetK = 70;

        $('.top-row').click(function () {
            $('.description').slideUp()
            $('.tab-row').removeClass('open')
            if ($(this).siblings('.description').is(":visible")) {
                $(this).siblings('.description').slideUp()
                $(this).parent().removeClass('open')
            } else {
                $(this).siblings('.description').slideDown()
                $(this).parent().addClass('open')
            }

        })
        $('.nav-menu ul a').click(function (e) {
            e.preventDefault()
            $('#magic-line').css({'opacity': 1})
            let id = $(this).attr('href')
            $('body, html').animate({
                scrollTop: $(id).offset().top - 100
            })
            if($window.width() < 768){
                $('.nav-menu ul a').removeClass('active')
                $(this).addClass('active')
            }
        })

        let scrollPos = 10

        $(document).scroll(function (e) {
            $('.scroll-line').each(function (i, el) {

                if ($(el).offset().top < $(document).scrollTop() + $(window).height() / 2 && $(el).offset().top + $(el).height() > $(document).scrollTop() + $(window).height() / 2) {
                    let id = $(el).attr('id')
                    $el = $('a[href=#' + id + ']').parent()
                    let leftPos = $el.position().left;
                    let newWidth = $el.width();

                    $('.nav-menu').find('#magic-line').stop().animate({
                        left: leftPos,
                        width: newWidth
                    });
                    if($window.width() < 768){
                        $('.nav-menu ul a').removeClass('active')
                        $('a[href=#' + id + ']').addClass('active')
                    }
                }

            })
            let info = $('.section-hero .square')
            let scrollTop = $(document).scrollTop()

            if ($(document).scrollTop() > 10) {

                $('#header').addClass('fixed')
            } else {
                $('#header').removeClass('fixed')
            }
            if ($(document).scrollTop() > 100) {

                $('#magic-line').css({'opacity': 1})
            } else {
                $('#magic-line').css({'opacity': 0})
            }
            if ($window.width() > 768) {


                if ($('.about-us-section .rectangle-wrapper').offset().top + $('.about-us-section .rectangle-wrapper').height() < scrollTop + $window.height()) {
                    $('.about-us-section .rectangle-wrapper').addClass('animate')
                }
                if ($('.our-core-values-section .wrapper-paragraph').offset().top < scrollTop + $window.height()) {
                    $('.our-core-values-section .rectangle-wrapper').addClass('animate')
                }
                $(' .our-funds-section .flex-layout  h2').each(function (i, el) {
                    if ($(el).offset().top + $(el).height() <
                        scrollTop + $window.height()) {
                        $(el).addClass('animate')
                    }
                })


            } else {
                headingBlockAnimate()
                if ($('.about-us-section .rectangle-wrapper').offset().top + $('.about-us-section .rectangle-wrapper').height() <
                    scrollTop + $window.height()) {
                    $('.vetrtical-text-mobile').addClass('animate')
                }
                if ($(' .our-funds-section .flex-layout:nth-of-type(odd) h2').offset().top + $(' .our-funds-section .flex-layout:nth-of-type(odd) h2').height() <
                    scrollTop + $window.height()) {
                    $(' .our-funds-section .flex-layout:nth-of-type(odd) h2').addClass('animate')
                }

                if ($(' .our-funds-section .flex-layout:nth-of-type(even) h3').offset().top <
                    scrollTop + $window.height()) {
                    $(' .our-funds-section .flex-layout:nth-of-type(even) h2').addClass('animate')
                }
                if ($('.logo-footer').offset().top + $(' .logo-footer').height() <
                    scrollTop + $window.height()) {
                    $(' .logo-footer').addClass('animate')
                }
            }


        });
    });


})(jQuery)


class Scroller {


    constructor(conditions) {
        this.lastCondition = false;
        this.lastPosition = jQuery(window).scrollTop();
        if (conditions) {
            this.conditions = conditions;
        } else {
            this.conditions = [];
        }

        this.addEvents();
    }

    addEvents() {
        jQuery(document).ready(this.initConditions.bind(this));
        jQuery(document).scroll(this.selectCondition.bind(this));
        jQuery(window).load(this.initConditions.bind(this));
        jQuery(window).resize(this.initConditions.bind(this));
    }

    initConditions() {

        for (var i = 0; i < this.conditions.length; i++) {
            this.conditions[i].condition.init();
        }
    }


    selectCondition() {
        var wts = jQuery(window).scrollTop();
        var direction = (wts - this.lastPosition > 0) ? true : false;
        var newCondition = false;
        for (var i = 0; i < this.conditions.length; i++) {
            if (this.conditions[i].condition.check(wts, direction)) {
                newCondition = this.conditions[i].condition;
                break;
            }
        }
        if (newCondition != this.lastCondition) {
            this.lastCondition && this.lastCondition.end(wts, direction);
            newCondition && newCondition.start();
            this.lastCondition = newCondition;
        } else {
            this.lastCondition && this.lastCondition.progress(wts, direction);
        }

        this.lastPosition = wts;
    }

    addCondition(condition, priority) {
        this.conditions.push(new ConditionWrapper(condition, priority))
        this.sort();
    }

    sort() {
        this.conditions.sort(function (a, b) {
            if (a.priority < b.priority) {
                return -1;
            }
            if (a.priority > b.priority) {
                return 1;
            }
            return 0;
        });
    }
}


class Condition {
    constructor(init, check, progress, start, end) {
        this.init = init ? init : this.init;
        this.check = check ? check : this.check;
        this.progress = progress ? progress : this.progress;
        this.start = start ? start : this.start;
        this.end = end ? end : this.end;
    }

    init() {
        return false;
    }

    start(wts, direction) {
        this.progress(wts, direction);
        return false;
    }

    progress(wts, direction) {
        return false;
    }

    end(wts, direction) {
        return false;
    }

    check(wts, direction) {
        return false;
    }
}

class ConditionWrapper {
    constructor(condition, priority) {
        this.condition = condition;
        this.priority = typeof priority != "undefined" ? priority : Number.MAX_SAFE_INTEGER - 100;
    }
}


(function ($) {
    /*******************************************************************************/
    const offsetK = 70

    const rectScroller = new Scroller();

    const El = $('.section-hero .square').eq(0);


    let ElH = El.height();

    //console.log(ElTop,'jkldsfjkldfsjkldfsjkldfsjklsdfjkldfsjkl');


    var headings = jQuery('.right-block .wrapper-square h3, #radiations .wrapper-square h3');
    headings.not(headings.eq(0)).css({opacity: 0});
    var maxIndex = headings.length - 1;
    if (!maxIndex || !El.length) {
        return;
    }
    var defaultConditionFlag = true
    const ElTop = jQuery('.right-block .wrapper-square h3, #radiations .wrapper-square h3').eq(0).offset().top;

    headings.each(function (index, el) {
        let $el = $(el);
        var sectionHeroHedingCondition = new Condition(function () {
                this.wWidth = $(window).width();
                this.wHeight = $(window).height()
                this.element = El;
                this.heading = $el;
                this.headingTop = this.heading.offset().top;
                this.headingHeight = this.heading.innerHeight();
            },
            function (wts, direction) {
                return this.wWidth > 768 &&
                    wts + ElTop + 150 >= this.heading.offset().top &&
                    (wts < (this.heading.offset().top + 150) || index == maxIndex)
                    ;
            },
            function (wts, direction) {
                this.element.css({transform: 'translateY(' + (this.heading.offset().top - ElTop) + 'px)'})
            },
            function (wts, direction) {
                this.heading.css({animation: 'none'})
                this.heading.stop().animate({opacity: 1}, 500);
                this.element.stop().animate({opacity: 1}, 200);
            },
            function (wts, direction) {
                this.heading.stop().animate({opacity: 0}, 500);
                if (index - (direction ? 0 : 1)) {
                    this.element.stop().animate({opacity: 0}, 200);

                    defaultConditionFlag = false;
                    /*setTimeout(function () {
                        defaultConditionFlag = true;
                    },200);*/
                } else {
                    defaultConditionFlag = true;
                }

            }
        );
        rectScroller.addCondition(sectionHeroHedingCondition, index * 10);
    });


    const defaultCondition = new Condition(
        function () {
            this.wWidth = $(window).width();
            this.wHeight = $(window).height();
            this.element = $('.section-hero .square').eq(0);
        },
        function (wts, direction) {
            this.wts = wts;
            return this.wWidth > 768;
        },
        function () {
            defaultConditionFlag && this.element.css({transform: 'translateY(' + (this.wts - offsetK - 100) + 'px)'});
        }
    );


    rectScroller.addCondition(defaultCondition);


    var ourStaffSection = $('section.our-staff-section');

    var ourStaffSectionCondition = new Condition(function () {
            this.wWidth = $(window).width();
            this.element = El;
            this.section = ourStaffSection;
        },
        function (wts, direction) {
            return this.wWidth > 768 &&
                wts >= this.section.offset().top - ElTop - 800 &&
                wts < this.section.offset().top - ElTop + 100
                ;
        },
        function () {
            defaultCondition.progress()
        },
        function () {
            this.element.stop().animate({opacity: 0}, 100);
            this.progress();
        },
        function () {
            this.element.stop().animate({opacity: 1}, 100)
            this.progress();
        }
    );
    //rectScroller.addCondition(ourStaffSectionCondition, 1000);

    $(window).load(function () {
        setTimeout(function () {
            rectScroller.initConditions();
            headings.eq(0).css({animation: 'none'})
            headings.eq(0).css({opacity: 1});
        }, 1500)

    })

})(jQuery)

